import request from '@/utils/request'
export function AddDealer(params) {
  return request({
    url: '/api/Dealer/Add',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function GetDealer(id) {
  return request({
    url: `/api/Dealer?para=${id}`
  })
}

export function AddorUpdateDealer(params) {
  return request({
    url: '/api/Dealer/AddorUpdate',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function UpdateDealer(params) {
  return request({
    url: '/api/Dealer/Update',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
export function GetDealerList(params) {
  return request({
    url: '/api/Dealer/List',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
export function GetDealerListPlus(params) {
  return request({
    url: '/api/Dealer/ListPlus',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function PageHelpSaleStat(params) {
  return request({
    url: '/api/Dealer/PageHelpSaleStat',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function PageCampaignStat(params) {
  return request({
    url: '/api/Dealer/PageCampaignStat',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function GetDealerTotalPlus(groupname, params) {
  return request({
    url: `/api/Dealer/PagePlusTotal?GroupName=${groupname}`,
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function GetOrderProductStatList(groupname, params) {
  return request({
    url: `/api/Dealer/OrderProductStatList?GroupName=${groupname}`,
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
export function GetDealerStatList(groupname, params) {
  return request({
    url: `/api/Dealer/DealerStatList?GroupName=${groupname}`,
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function GetHelpUserStatList(groupname, params) {
  return request({
    url: `/api/Dealer/HelpUserStatList?GroupName=${groupname}`,
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}


export function GetDealerCampaignList(params) {
  return request({
    url: '/api/DealerCampaign/List',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function AddDealerCampaign(params) {
  return request({
    url: '/api/DealerCampaign/Add',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function deleteDealerCampaign(params) {
  return request({
    url: '/api/DealerCampaign/Delete',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}
export function UpdateDealerCampaign(params) {
  return request({
    url: '/api/DealerCampaign/Update',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function GetDealerCampaign(id) {
  return request({
    url: `/api/DealerCampaign?para=${id}`
  })
}

export function GetSystemProduct(params) {
  return request({
    url: '/api/DealerCampaign/ListProduct',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function GetRoleType() {
  return request({
    url: `/api/DealerCampaign/GetRoleType`
  })
}
