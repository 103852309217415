<template>
    <div class="container">
        <van-loading class="loading" color="#9d1d22" text-color="#9d1d22" v-if="loading" size="24px">加载中...</van-loading>

        <div v-if="loading == false">
            <van-sticky>
                <van-nav-bar :title="title" left-arrow @click-left="goBack()">
                </van-nav-bar>
            </van-sticky>
            <van-form @submit="onSubmit" ref="dealerForm" @failed="onFailed">
                <van-cell-group inset>
                    <van-field label="名称" v-model="dealer.name" required :rules="[{ required: true, message: '请填写名称' }]" />

                    <van-field readonly clickable name="area" :value="dealer.area" label="地区选择" placeholder="点击选择省市区"
                        @click="showArea = true" />
                    <van-popup v-model="showArea" position="bottom">
                        <van-area :area-list="areaList" @confirm="onConfirmArea" @cancel="showArea = false" />
                    </van-popup>


                    <van-field label="地址" v-model="dealer.address" required
                        :rules="[{ required: true, message: '请填写地址' }]" />
                    <van-field label="类型" name="typeName">
                        <template #input>
                            <van-radio-group v-model="typeName" direction="horizontal" @change="ChangeTypeName">
                                <van-radio @click="ClickTypeName('经销商')" name="经销商">经销商</van-radio>
                                <van-radio name="团购客户">团购客户</van-radio>
                                <van-radio name="烟酒店">烟酒店</van-radio>
                                <van-radio name="终端店">终端店</van-radio>
                                <van-radio name="其它老板">其它老板</van-radio>
                                <van-radio name="终端消费者">终端消费者</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <div class="contact-wrapper">
                        <div v-for="(contact, index) in filteredContacts" :key="index">
                            <div class="contact-header">联系人 {{ index + 1 }}</div>
                            <div class="contact-item">
                                <div style="width:90%">
                                    <van-field v-model="contact.contacts" label="姓名" placeholder="请输入姓名"
                                        required></van-field>
                                    <van-field v-model="contact.phone" label="电话" placeholder="请输入电话" required></van-field>
                                </div>
                                <van-icon v-if="!contact.isdelete" name="cross" class="delete-icon"
                                    @click.prevent="removeContact(index)" />
                            </div>
                        </div>
                        <div style="margin: 16px; text-align: center;">
                            <van-button plain type="info" round size="small" @click.prevent="addContact">添加联系人</van-button>
                        </div>
                    </div>


                    <van-field label="规模" v-model="dealer.scale" />

                    <van-field v-model="dealer.established" is-link readonly label="成立时间" placeholder="点击选择时间"
                        @click="showEstablishedPicker = true" />
                    <van-popup v-model="showEstablishedPicker" position="bottom" :style="{ height: '30%' }">
                        <van-datetime-picker @cancel="showEstablishedPicker = false" @confirm="onConfirmEstablished"
                            v-model="establishedPickerDate" type="date" title="成立时间" />
                    </van-popup>

                    <van-field label="资源" v-model="dealer.resource" />

                    <!-- <van-field name="switch" label="是否连锁">
                        <template #input>
                            <van-switch v-model="dealer.chains" />
                        </template>
                    </van-field> -->
                    <van-field label="客户资源" v-model="dealer.customer" />


                    <van-field readonly clickable name="area" :value="dealer.department" label="地区选择" placeholder="点击选择省市区"
                        required :rules="[{ required: true, message: '请填写名称' }]" @click="showdepartment = true" />
                    <van-popup v-model="showdepartment" position="bottom">
                        <div style=" margin: 0 auto; max-width: 750px;">
                            <van-tree-select :height="450" :items="ccitems" :active-id.sync="activeId"
                                :main-active-index.sync="activeIndex" @click-item="onitemclick" />
                            <van-row>
                                <van-col span="5" offset="1"> <van-button type="info" block round
                                        @click.prevent="clearDepartment()">
                                        重置
                                    </van-button></van-col>
                                <van-col span="16" offset="1"> <van-button type="info" block round
                                        @click.prevent="changeDepartment()">
                                        确认
                                    </van-button></van-col>
                            </van-row>
                        </div>
                    </van-popup>

                    <van-field label="跟进销售" v-model="dealer.followSale" />
                </van-cell-group>
                <div style="margin: 16px;">
                    <van-button round block type="primary" native-type="submit">
                        提交
                    </van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>
  
<script>
import { GetDealer, AddorUpdateDealer } from "@/api/dealer";
import { Toast } from "vant";
import { areaList } from "@/utils/dealerArea";
import { BrandDepartmentTree } from "@/utils/datetype";
//import { GetExamQuestionForUser, AddAnswer } from "@/api/exam";

//import { uploadfileplus } from "@/api/file";

export default {
    data() {
        return {
            show: false,
            loading: true,
            showEstablishedPicker: false,
            establishedPickerDate: new Date(),
            showArea: false,
            areaList: areaList, // 数据格式见 Area 组件文档
            title: "新增经销商",
            dealer: {
                id: "",
                name: "",
                province: "",
                city: "",
                county: "",
                area: "",
                typeID: "1",
                scale: "",
                established: "",
                address: "",
                resource: "",
                chains: true,
                customer: "",
                sourceId: 0,
                department: "",
                center: "",
                followSale: "",
                status: 0,
                contactsList: [{
                    id: 0,
                    contacts: "",
                    phone: "",
                    isDelete: false
                }]

            },
            typeName: "",
            showdepartment: false,
            activeId: 1,
            activeIndex: 0,
            ccitems: BrandDepartmentTree,

        };
    },
    created() {
        this.initData();
    },
    computed: {
        filteredContacts() {
            return this.dealer.contactsList.filter(contact => !contact.isDelete);
        }
    },
    components: {
    },


    methods: {

        onFailed(failed) {
            const errors = failed.errors;
            this.$refs.dealerForm.scrollToField(errors[0].name, true);
        },

        ClickTypeName(data) {
            console.log("ClickTypeName" + data);
            this.typeName = data;
        },
        ChangeTypeName(data) {
            console.log("ChangeTypeName" + data);
            this.typeName = data;
        },
        onitemclick(data) {

            this.dealer.department = data.text;
            if (this.activeIndex == 0) {
                this.dealer.center = "品牌一部";
            }
            else {
                this.dealer.center = "品牌二部";
            }
            //this.$router.push({path:'/course/list?cid'+data.id})
        },
        // onitemnavclick(index) {
        //   if (this.ccitems[index].children.length == 0) {
        //     this.$refs.item.toggle();

        //     this.page.filterData.schoolClass = this.ccitems[index].id;
        //     this.page.filterData.schoolClass2 = 0;
        //     this.onSearch();

        //     //this.$router.push({path:'/course/list?cid='+this.ccitems[index].id})
        //   }
        // },
        clearDepartment() {
            this.activeId = 1;
            this.activeIndex = 0;
            this.dealer.department = "";

        },
        changeDepartment() {
            this.showdepartment = false;
        },
        async initData() {
            this.loading = true;
            let para = this.$route.query.para;
            if (para) {
                let result = await GetDealer(para);
                if (result.data.success) {
                    this.dealer = Object.assign(this.dealer, result.data.data)
                    this.title = this.dealer.name;
                }
                this.typeName = this.dealer.typeName;
                if (this.dealer.center == "品牌一部") {
                    this.activeIndex = 0
                }
                if (this.dealer.center == "品牌二部") {
                    this.activeIndex = 1
                }
                if (this.dealer.department) {
                    this.activeId = this.dealer.department
                }


            }
            if (this.dealer.contactsList == undefined) {
                this.dealer.contactsList = [{
                    id: 0,
                    contacts: "",
                    phone: "",
                    isDelete: false
                }]
            }
            this.loading = false;
        },
        onConfirmArea(values) {
            this.dealer.area = values
                .filter((item) => !!item)
                .map((item) => item.name)
                .join('/');
            this.showArea = false;
        },
        onConfirmEstablished() {
            Date.prototype.Format = function (fmt) {
                //author: meizz
                var o = {
                    "M+": this.getMonth() + 1, //月份
                    "d+": this.getDate(), //日
                    "h+": this.getHours(), //小时
                    "m+": this.getMinutes(), //分
                    "s+": this.getSeconds(), //秒
                    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
                    S: this.getMilliseconds(), //毫秒
                };
                if (/(y+)/.test(fmt))
                    fmt = fmt.replace(
                        RegExp.$1,
                        (this.getFullYear() + "").substr(4 - RegExp.$1.length)
                    );
                for (var k in o)
                    if (new RegExp("(" + k + ")").test(fmt))
                        fmt = fmt.replace(
                            RegExp.$1,
                            RegExp.$1.length == 1
                                ? o[k]
                                : ("00" + o[k]).substr(("" + o[k]).length)
                        );
                return fmt;
            };

            this.dealer.established = this.establishedPickerDate.Format("yyyy-MM-dd"),
                this.showEstablishedPicker = false;

        },
        async onSubmit() {
            this.dealer.typeName = this.typeName;
            let result = await AddorUpdateDealer(this.dealer)
            if (result.data.success) {
                Toast("提交成功");
                this.goBack();
            } else {
                Toast(result.data.errorMessage);
            }
        },
        addContact() {
            this.dealer.contactsList.push({
                id: 0,
                contacts: "",
                phone: "",
                isDelete: false
            });
        },
        removeContact(index) {
            const contact = this.filteredContacts[index];
            const contactIndex = this.dealer.contactsList.findIndex(c => c === contact);
            if (contactIndex !== -1) {
                this.dealer.contactsList[contactIndex].isDelete = true;
            }
        },
        goBack() {
            if (this.$route.query.back) {
                this.$router.back();
            }
            else {
                this.$router.replace({ path: '/dealer/dealercampaign', query: { para: this.$route.query.para } })
            }
        },
        ChangecheckedDepartment(value) {
            this.dealer.hrDepartmentId = value;
        },

    },
};
</script>
  
<style lang="less" scoped>
.loading {
    text-align: center;
}

.container {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;


    //padding: 0px 10px 20px 10px;
}

.contact-wrapper {
    margin-bottom: 10px;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}


.contact-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.delete-icon {
    margin-left: 10px;
    font-size: 20px;
    color: #999;
    cursor: pointer;
}
</style>